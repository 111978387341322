// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-en-developments-js": () => import("./../src/pages/en/developments.js" /* webpackChunkName: "component---src-pages-en-developments-js" */),
  "component---src-pages-en-faq-js": () => import("./../src/pages/en/faq.js" /* webpackChunkName: "component---src-pages-en-faq-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-media-js": () => import("./../src/pages/en/media.js" /* webpackChunkName: "component---src-pages-en-media-js" */),
  "component---src-pages-en-partners-js": () => import("./../src/pages/en/partners.js" /* webpackChunkName: "component---src-pages-en-partners-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-ontwikkelingen-js": () => import("./../src/pages/ontwikkelingen.js" /* webpackChunkName: "component---src-pages-ontwikkelingen-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-veelgestelde-vragen-js": () => import("./../src/pages/veelgestelde-vragen.js" /* webpackChunkName: "component---src-pages-veelgestelde-vragen-js" */)
}

